import React from "react";
import "./Footer.scss";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer>
      <div className="main-content">
        <div className="left">
          <h1 className="big-title">Azurea Esthetic Travel</h1>
          <p className="pgh">
            Nous vous aidons à planifier votre voyage et votre intervention en
            Tunisie. Azurea Esthetic Travel, c'est un accompagnement
            personnalisé avec possibilité de rendez-vous à Nice et un réseau de
            cliniques et de chirurgiens chevronnés en Tunisie.
          </p>
          <button
            className="light-button btn"
            onClick={() => navigate("/contact")}
          >
            Demander un devis
          </button>
        </div>
        <div className="right">
          <div>
            <img src="/img/phone.svg" alt="" />
            <span>
              <a href="tel:+33650964053" className="pgh">
                +33 6 50 96 40 53
              </a>
              <br />
              <a href="tel:+33423133176" className="pgh">
                +33 4 23 13 31 76
              </a>
            </span>
          </div>
          <div>
            <img src="/img/mail.svg" alt="" />
            <a href="mailto:contact@azurea-esthetic-travel.fr" className="pgh">
              contact@azurea-esthetic-travel.fr
            </a>
          </div>
          <div>
            <img src="/img/facebook_IwPkIotr.svg" alt="" />
            <a
              href="https://www.facebook.com/AZUREA6"
              target="_blank"
              rel="noreferrer"
              className="pgh"
            >
              Azurea Esthetic Travel
            </a>
          </div>
          <div>
            <img
              src="/img/instagram.png"
              alt=""
              // style={{ background: "white" }}
            />
            <a
              href="https://www.instagram.com/azurea_06/"
              target="_blank"
              rel="noreferrer"
              className="pgh"
            >
              @azurea_06
            </a>
          </div>
          <div>
            <img src="/img/pin.svg" alt="" />
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.google.fr/maps/place/40+Av.+Sainte-Marguerite,+06200+Nice/@43.6784071,7.2182496,17z/data=!3m1!4b1!4m5!3m4!1s0x12cdd1aa8fa8abdd:0xc19435eca7b00989!8m2!3d43.6784071!4d7.2204383"
              className="pgh one-line"
            >
              41 avenue Louis Cappatti 06200 Nice, France
            </a>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.google.com/maps/place/41+Av.+Louis+Cappatti,+06200+Nice/@43.6847643,7.2084548,17z/data=!3m1!4b1!4m5!3m4!1s0x12cdd1a44c24db05:0xcafd5f3140f76bef!8m2!3d43.6847643!4d7.2084548"
              className="pgh two-lines"
            >
              41 avenue louis cappatti
              <br />
              06200
              <br />
              Nice, France
            </a>
          </div>
        </div>
      </div>
      <span className="sp">
        Tous droits réservés Azuréa Esthetic Travel © {new Date().getFullYear()}{" "}
        - <Link to="/mentions-legales">Mentions légales</Link> -{" "}
        <Link to="/cgu">CGU</Link> -{" "}
        <Link to="/confidentialite">Vie privée</Link>
      </span>
    </footer>
  );
};

export default Footer;
